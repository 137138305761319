export const challenges = {
  "standard": [
    "Your Favorite Album",
    "Your Pride and Joy",
    "Your Most-Spun Record",
    "It Came In Damaged, But I Kept It Anyway",
    "Introduce Yourself With A Record",
    "Your Most Recent Album",
    "Record From The Year You Were Born",
    "Rarest Album You Have",
    "The First Record You Ever Bought",
    "Your Favorite EP",
    "Your Favorite 10\"",
    "Your Favorite 7\"",
    "Album With A Great First Track",
    "Album With A Great Last Track",
    "Album That Shaped Your Music Taste",
    "Front-Loaded Album",
    "Back-Loaded Album",
    "I Only Like One Track",
    "Impulse Purchase",
    "Signed Album",
    "Bought At A Concert",
    "Album By A Woman",
    "Underrated Album",
    "Happy Memory",
    "Record From A Deceased Artist",
    "Gettin' Busy Music",
    "Guilty Pleasure",
    "Non-Music Record",
    "Band From Your Birth State",
    "Last Album You Listened To",
    "Album You Play Too Much",
    "Most-Collected Artist",
    "Best LOUD Album",
    "Best Quiet Album",
    "An Album Everyone Should Own",
    "Why You Collect In One Album",
    "The Weirdest Album You Own",
    "Foreign Language Album",
    "Box Set",
    "Record You'd Give To An Alien",
    "An Album From Your Childhood",
    "An Album From Your Teen Years",
    "An Album That Grew On You",
    "Album With The Greatest Lyrics",
    "Self-Titled Album",
    "Best Debut Album",
    "Best Sophomore Album",
    "Your Favorite Gatefold",
    "Lucky Find",
    "Thrift Store Pickup",
    "Favorite Gifted Album",
    "Best Compilation Album",
    "Album You'd Be Buried With",
    "Album Whose Artwork You'd Tattoo Across Your Back",
    "Best Live Album",
    "Artist You've Seen Live",
    "Best Concept Album",
    "Original Score or Soundtrack",
    "Best Solo/Side Project",
    "Best Album By a Duo",
    "Best Album By a Trio",
    "Collab Album",
    "Sunny Day Record",
    "Rainy Day Record",
    "Late Night Record",
    "Get Ready For Work Music",
    "Summer Album",
    "Fall Album",
    "Winter Album",
    "Spring Album",
    "Record That Reminds You of a Vacation",
    "Record That Reminds You of a Place",
    "Your Favorite Saturday Record",
    "Your Favorite Sunday Record",
    "Your Favorite Used Record",
    "A Record That Motivates You",
    "A Record That Has That ONE Track You Skip Over",
    "I Met The Artist!",
    "Album With The Best Vocals",
    "Album With The Best Guitarist",
    "Album With The Best Bassist",
    "Album With The Best Drummer",
    "Album With The Best Keyboards",
    "Your Top 5 Rappers",
    "Best Producer",
    "Rap Album With The Best Bar",
    "Rap Album With The Best Beat",
    "Album With The Best Feature",
    "A Record By A Local Artist",
    "A Record That Surprised You",
    "A Record You Splurged On",
    "A Record That Makes You Happy",
    "A Record That Makes You Sad",
    "A Record That Makes You Cry",
    "A Record That Makes You Want To Break Something",
    "A Record You Haven't Opened Yet",
    "Your Favorite Used Record",
    "I Can't Believe I Snagged A Copy!",
    "Pick Out A Record With Your Eyes Closed",
    "Your Holy Grail",
    "Your White Whale",
    "Album With A One-Word Title",
    "Album With The Longest Title",
    "Hidden Message/Deadwax Etch",
    "An Album You Have Multiples Of",
    "Least You Spent On An Album",
    "Most You Spent On An Album",
    "Album You Got From Your Local Record Store",
    "Mood Booster Album",
    "Your Oldest Record",
    "The House Is Burning! Pick One Album To Save!",
    "Album Cover With No Text",
    "Your Current Obsession",
    "RSD Release",
    "Your Best Sounding Album",
    "Best Liner Notes",
    "Best Album Art",
    "The Worst Album You Own",
    "Most Disappointing Pressing",
    "Great Album, But It's Damaged",
    "Your Ugliest Record",
    "Your Favorite 2LP",
    "Your Favorite 3LP",
    "Split Color Album",
    "Splatter Album",
    "Swirl Album",
    "Clear Album",
    "Solid Color Album",
    "Picture Disc",
    "Etched Album",
    "Favorite Color/Effect",
    "Numbered Pressing",
    "Record That Makes You Feel Nostalgic",
    "Go-To Album When You're Feeling Sad",
    "Go-To Album When You're Feeling Happy",
    "Go-To Album When You're Feeling Angry",
    "Go-To Album When Setting Up A New System",
    "Album You Thought Would Be Amazing But Was Just Alright",
    "Album That Made You Want To Start Collecting",
    "Record That Was Hard To Find",
    "An Album You Consider A Masterpiece",
    "A Record No Collection Is Complete Without",
    "A Record You'll Never Get Rid Of No Matter What",
    "A Record That Is Perfect Front to Back",
    "A Record You Didn't Hear Before Buying",
    "One Of Your Favorite Albums From Last Year",
    "One Of Your Favorite Albums From This Year",
    "The Last Album You Want To Hear Before You Die",
    "A Record That Helped You Through A Hard Time",
    "An Artist You Want A Private Concert From",
    "Die-Cut Album"
  ],
  "dynamic": [
    "Your Favorite {{modifier}} Album",
    "Your Favorite {{modifier}} Artist"
  ]
};
