import React, { useEffect, useState } from 'react';
import html2canvas from "html2canvas";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import {getDaysInMonth} from "../../lib/helpers";
import ChallengeControl from "./ChallengeControl";
import Tray from "./Tray";
import one from '../../images/1.png';
import logo from '../../images/logo.svg';
import phoneLogo from '../../images/logo_phoneprint.svg';

function MonthGenerator() {
    const [title, setTitle] = useState<string>('');
    const [days, setDays] = useState<number>(0);
    const [background, setBackground] = useState<string>(one);
    const [includeSubgenres, setIncludeSubgenres] = useState<boolean>(false);
    const [printing, setPrinting] = useState<boolean>(false);
    const [zoom, setZoom] = useState<boolean>(false);
    const [selectedMonth, setSelectedMonth] = useState<number>(0);
    const [seed, setSeed] = useState<number>(0);
    const [challengeList, setChallengeList] = useState<string[]>([]);
    const [headlineFont, setHeadlineFont] = useState<string>('dolce_vita');
    const [headlineFontSize, setHeadlineFontSize] = useState<string>('med');
    const [bodyFont, setBodyFont] = useState<string>('open_sans');
    const [bodyFontSize, setBodyFontSize] = useState<string>('med');
    const [invertFont, setInvertFont] = useState<boolean>(false);

    const printRef = React.useRef<any>();

    useEffect(() => {
        const month = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];

        const d = new Date();
        const nextMonth = d.getMonth()+1 === 12 ? 0 : d.getMonth()+1;
        setDays(getDaysInMonth(nextMonth+1));
        setSelectedMonth(nextMonth+1);
        !title && setTitle(`${month[nextMonth]} Vinyl Challenge`)
    }, []);

    const refresh = () => {
        setSeed(Math.random());
    };

    const isMobile = () => {
        const query = `(max-width: 500px)`;
        return window.matchMedia(query).matches;
    }

    const generateImage = async () => {
        if (isMobile()) await setPrinting(true);
        const element = printRef.current;
        const canvas = await html2canvas(element);

        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');

        if (typeof link.download === 'string') {
            link.href = data;
            link.download = 'image';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            window.open(data);
        }
        if (isMobile()) await setPrinting(false);
    };

    const changeFontSize = (element: string, upOrDown: string) => {
        const sizes = [
            'xsm',
            'sm',
            'med',
            'lg',
            'xlg'
        ];
        if (element === 'headline') {
            const current = sizes.indexOf(headlineFontSize);
            if (upOrDown === 'up') {
                current !== 4 && setHeadlineFontSize(sizes[current+1]);
            }
            if (upOrDown === 'down') {
                current !== 0 && setHeadlineFontSize(sizes[current-1]);
            }
        }
        if (element === 'body') {
            const current = sizes.indexOf(bodyFontSize);
            if (upOrDown === 'up') {
                current !== 4 && setBodyFontSize(sizes[current+1]);
            }
            if (upOrDown === 'down') {
                current !== 0 && setBodyFontSize(sizes[current-1]);
            }
        }
    };

    return (
        <div className="MonthGenerator" key={seed}>
            <button type="button" className="download" onClick={() => generateImage()}>
                <FontAwesomeIcon icon={faDownload} />
            </button>
            <button type="button" className={`zoom${zoom ? ' active' : ''}`} onClick={() => setZoom(!zoom)}>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
            <div className={`output${zoom ? ' zoom' : ''}${invertFont ? ' invertFont' : ''}`} style={{ backgroundImage: `url(${background})` }} ref={printRef}>
                <h2 className={`${headlineFont} ${headlineFontSize}`}>{title}</h2>
                <main className={`${bodyFont} ${bodyFontSize}`}>
                    {[...Array(getDaysInMonth(days))].map((_,i) =>
                        <ChallengeControl
                            key={i}
                            number={i+1}
                            includeSubgenres={includeSubgenres}
                            challengeList={challengeList}
                            setChallengeList={setChallengeList}
                        />)}
                </main>
                <div className="logo">
                    {!printing && <img src={logo} alt="Vinyl Challenge Logo"/>}
                    {printing && <img src={phoneLogo} alt="Vinyl Challenge Logo"/>}
                    <span>Generated with VinylChallenge.app</span>
                </div>
            </div>
            <Tray
                setBackground={setBackground}
                setDays={setDays}
                setTitle={setTitle}
                title={title}
                includeSubgenres={includeSubgenres}
                setIncludeSubgenres={setIncludeSubgenres}
                invertFont={invertFont}
                setInvertFont={setInvertFont}
                selectedMonth={selectedMonth}
                refresh={refresh}
                setHeadlineFont={setHeadlineFont}
                setBodyFont={setBodyFont}
                changeFontSize={changeFontSize}
            />
        </div>
    );
}

export default MonthGenerator;
