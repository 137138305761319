import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import './App.css';
import logo from './images/logo.svg'
import MonthGenerator from "./components/MonthGenerator/MonthGenerator";
import About from "./components/About/About";

/**
 * TODO:
 *
 * - store preferences in indexedDB
 * - store generated images as blobs in indexedDB
 * - dedupe issue
 * - shake to challenge
 *
 */

function App() {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className="App">
        <nav>
          <img alt="Vinyl Challenge" src={logo} />
            <button type="button" onClick={() => setShowInfo(!showInfo)}>
                <FontAwesomeIcon icon={faCircleInfo} />
            </button>
        </nav>
      <MonthGenerator />
        <About show={showInfo} close={setShowInfo} />
    </div>
  );
}

export default App;
