import React, {Dispatch, useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faEdit, faClose, faHome } from "@fortawesome/free-solid-svg-icons";
import {getModifiers, getSubgenreKeys, getSubgenres, randomChallenge} from "../../lib/randomizer";
import {helpers} from "../../data/helpers";

interface challengeControlProps {
    number: number;
    includeSubgenres: boolean;
    challengeList: string[];
    setChallengeList: Dispatch<React.SetStateAction<string[]>>;
}

const ChallengeControl = ({ number, includeSubgenres, challengeList, setChallengeList }: challengeControlProps) => {
    const [challenge, setChallenge] = useState('');
    const [showBuilder, setShowBuilder] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [category, setCategory] = useState<any>('');
    const [modifier, setModifier] = useState<string>('');
    const [enableSubcategory, setEnableSubcategory] = useState<boolean>(true);
    const [subcategory, setSubcategory] = useState<string>('');
    const [noun, setNoun] = useState<string>("Album");

    useEffect(() => {
        getRandomChallenge();
    }, []);

    const getRandomChallenge = () => {
        setChallenge(randomChallenge(includeSubgenres, challengeList, setChallengeList));
    };

    const getCategory = () => {
        // @ts-ignore
        return getModifiers()[category];
    };

    const reset = () => {
        setCategory('');
        setModifier('');
        setSubcategory('');
    };

    const generateChallenge = () => {
        let mod = modifier;
        if (subcategory) {
            mod = subcategory;
        }
        setChallenge(`Your Favorite ${mod} ${noun}`);
        setShowBuilder(false);
        setOpenEdit(false);
    };

    return (
        <div className={`ChallengeControl ${openEdit ? 'open' : 'closed'}`}>
            <div className="display">
                <button type="button" onClick={() => setOpenEdit(true)}>
                    <b>{`${number}: `}</b>{`${challenge}`}
                </button>
            </div>
            <div className="control">
                <div className="wrapper">
                    <label htmlFor="control">{`Edit Challenge #${number}`}</label>
                    <ul>
                        <li>
                            <button type="button" onClick={() => getRandomChallenge()}>
                                <FontAwesomeIcon icon={faRefresh} />
                            </button>
                        </li>
                        <li>
                            <button type="button" className={showBuilder ? 'showBuilder' : ''} onClick={() => setShowBuilder(!showBuilder)}>
                                <FontAwesomeIcon icon={faEdit} />
                            </button>
                        </li>
                        <li>
                            <button type="button" className="close" onClick={() => setOpenEdit(false)}>
                                <FontAwesomeIcon icon={faClose} />
                            </button>
                        </li>
                    </ul>
                    <div className={showBuilder ? 'hide' : ''}>
                        <input placeholder="Enter a title for your challenge" id="control" onChange={e => setChallenge(e.target.value)} value={challenge} />
                    </div>
                    <div className={`builder${showBuilder ? '' : ' hide'}`}>
                        <span>Your Favorite</span>
                        <select className={category ? 'hide' : ''} onChange={e => setCategory(e.target.value)}>
                            <option>Select a Category</option>
                            { Object.keys(helpers).filter(h => h !== 'subgenres').map(h => <option key={h} value={h}>{h}</option>) }
                        </select>
                        {category && category !== 'genres' && <select onChange={e => setModifier(e.target.value)}>
                            <option>Select a Modifier</option>
                            {getCategory().map((h: any) => <option key={h} value={h}>{h}</option>)}
                        </select>}
                        {category && !modifier && category === 'genres' && <select onChange={e => setModifier(e.target.value)}>
                            {getSubgenreKeys().map((h: any) => <option key={h} value={h}>{h}</option>)}
                        </select>}
                        {modifier && category === "genres" && <select onChange={e => setSubcategory(e.target.value)}>
                            <option value={modifier}>{modifier}</option>
                            {getSubgenres(modifier).map((h: any) => <option key={h} value={h}>{h}</option>)}
                        </select>}
                        <select onChange={e => setNoun(e.target.value)}>
                            <option value="Album">Album</option>
                            <option value="Artist">Artist</option>
                        </select>
                    </div>
                    <div className={`buttons${showBuilder ? '' : ' hide'}`}>
                        {category && <button type="button" onClick={() => reset()}>
                            Reset
                        </button>}
                        {category && <button type="button" onClick={() => generateChallenge()}>
                            Done
                        </button>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChallengeControl;
