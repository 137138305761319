import React, { Dispatch, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import one from '../../images/1.png'
import two from '../../images/2.png'
import three from '../../images/3.png'
import four from '../../images/4.png'
import five from '../../images/5.png'
import six from '../../images/6.png'
import seven from '../../images/7.png'
import eight from '../../images/8.png'
import nine from '../../images/9.png'
import ten from '../../images/10.png'

interface trayProps {
    title: string;
    includeSubgenres: boolean;
    setIncludeSubgenres: Dispatch<React.SetStateAction<boolean>>;
    invertFont: boolean;
    setInvertFont: Dispatch<React.SetStateAction<boolean>>;
    selectedMonth: number;
    setBackground: Dispatch<React.SetStateAction<string>>;
    setDays: Dispatch<React.SetStateAction<number>>;
    setTitle: Dispatch<React.SetStateAction<string>>;
    setHeadlineFont: Dispatch<React.SetStateAction<string>>;
    setBodyFont: Dispatch<React.SetStateAction<string>>;
    changeFontSize: Function;
    refresh: Function;
};

const Tray = ({
              setBackground,
              setDays,
              setTitle,
              title,
              includeSubgenres,
              setIncludeSubgenres,
              invertFont,
              setInvertFont,
              selectedMonth,
              refresh,
              setHeadlineFont,
              setBodyFont,
              changeFontSize }: trayProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const changeMonth = (val: number) => {
        const month = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];

        setDays(val);
        setTitle(`${month[val-1]} Vinyl Challenge`)
    };

    return (
        <div className={`${isOpen ? 'open' : 'closed'} Tray`}>
            <button type="button" className="openClose" onClick={() => setIsOpen(!isOpen)}>
                <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronUp} />
            </button>
            <div className="wrapper">
                <div className="space">
                    <label htmlFor="title">Title:</label>
                    <input placeholder="Enter a title for your challenge" id="title" className="title" onChange={e => setTitle(e.target.value)} value={title} />
                    <div className="third">
                        <select onChange={e => changeMonth(Number(e.target.value))}>
                            <option value="1" selected={selectedMonth === 1}>January</option>
                            <option value="2" selected={selectedMonth === 2}>February</option>
                            <option value="3" selected={selectedMonth === 3}>March</option>
                            <option value="4" selected={selectedMonth === 4}>April</option>
                            <option value="5" selected={selectedMonth === 5}>May</option>
                            <option value="6" selected={selectedMonth === 6}>June</option>
                            <option value="7" selected={selectedMonth === 7}>July</option>
                            <option value="8" selected={selectedMonth === 8}>August</option>
                            <option value="9" selected={selectedMonth === 9}>September</option>
                            <option value="10" selected={selectedMonth === 10}>October</option>
                            <option value="11" selected={selectedMonth === 11}>November</option>
                            <option value="12" selected={selectedMonth === 12}>December</option>
                        </select>
                    </div>
                    <div className="third">
                        <input type="checkbox" id="subgenres" onChange={() => setIncludeSubgenres(!includeSubgenres)} checked={includeSubgenres} />
                        <label htmlFor="subgenres">
                            Include Subgenres
                        </label>
                    </div>
                    <div className="third">
                        <button type="button" onClick={() => refresh()}>
                            Get New Challenges
                        </button>
                    </div>
                    <div className="full font">
                        <label>Headline Font:</label>
                        <select onChange={e => setHeadlineFont(e.target.value)}>
                            <option value="dolce_vita">Dolce Vita</option>
                            <option value="synthetic">Synthetic</option>
                            <option value="flick">Flick</option>
                        </select>
                        <button type="button" onClick={() => changeFontSize('headline', 'down')}>
                            <FontAwesomeIcon icon={faMinus} />
                        </button>
                        <button type="button" onClick={() => changeFontSize('headline', 'up')}>
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                    <div className="full font">
                        <label>Body Font:</label>
                        <select onChange={e => setBodyFont(e.target.value)}>
                            <option value="open_sans">Open Sans</option>
                            <option value="roboto_slab">Roboto Slab</option>
                            <option value="vina_sans">Vina Sans</option>
                        </select>
                        <button type="button" onClick={() => changeFontSize('body', 'down')}>
                            <FontAwesomeIcon icon={faMinus} />
                        </button>
                        <button type="button" onClick={() => changeFontSize('body', 'up')}>
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                    <div className="full">
                        <input type="checkbox" id="invertFont" onChange={() => setInvertFont(!invertFont)} checked={invertFont} />
                        <label htmlFor="invertFont">
                            Invert Font Color
                        </label>
                    </div>
                    <label>Background:</label>
                    <div className="backgrounds">
                        <button type="button" className="one" onClick={() => setBackground(one)}>
                            <img alt="background one" src={one} />
                        </button>
                        <button type="button" className="two" onClick={() => setBackground(two)}>
                            <img alt="background two" src={two} />
                        </button>
                        <button type="button" className="three" onClick={() => setBackground(three)}>
                            <img alt="background three" src={three} />
                        </button>
                        <button type="button" className="four" onClick={() => setBackground(four)}>
                            <img alt="background four" src={four} />
                        </button>
                        <button type="button" className="five" onClick={() => setBackground(five)}>
                            <img alt="background five" src={five} />
                        </button>
                        <button type="button" className="six" onClick={() => setBackground(six)}>
                            <img alt="background six" src={six} />
                        </button>
                        <button type="button" className="seven" onClick={() => setBackground(seven)}>
                            <img alt="background seven" src={seven} />
                        </button>
                        <button type="button" className="eight" onClick={() => setBackground(eight)}>
                            <img alt="background eight" src={eight} />
                        </button>
                        <button type="button" className="nine" onClick={() => setBackground(nine)}>
                            <img alt="background nine" src={nine} />
                        </button>
                        <button type="button" className="ten" onClick={() => setBackground(ten)}>
                            <img alt="background ten" src={ten} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tray;
