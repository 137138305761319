import { challenges } from "../data/challenges";
import { helpers } from "../data/helpers";

const min = Math.floor(0);

const randomWholeNumber = max => Math.floor(Math.random() * (max - min + 1));
const altRandomWholeNumber = max => Math.floor(Math.random() * (max - min + 1));

const randomModifier = (includeSubgenres, noHolidays, isAlbum) => {
    let categories = Object.keys(helpers);
    if (includeSubgenres) {
        helpers.subgenres = Object.values(helpers.subgenres).flat();
    }
    else {
        categories = categories.filter(c => c !== 'subgenres')
    }
    if (noHolidays) {
        categories = categories.filter(c => c !== 'holidays')
    }
    if (!isAlbum) {
        categories = categories.filter(c => c !== 'colors')
    }
    const modArray = helpers[categories[randomWholeNumber(categories.length - 1)]];
    return modArray[randomWholeNumber(modArray.length - 1)];
};

export const randomChallenge = (includeSubgenres = false, challengeList, setChallengeList) => {
    const whichType = Math.random();
    if (whichType > .4) {
        const challenge = challenges.standard[randomWholeNumber(challenges.standard.length - 1)];
        if (challengeList.includes(challenge)) {
            const altChallenge = challenges.standard[randomWholeNumber(challenges.standard.length - 1)];
            setChallengeList([...challengeList, altChallenge]);
            return altChallenge;
        }
        setChallengeList([...challengeList, challenge]);
        return challenge;
    }
    else {
        const challenge = challenges.dynamic[randomWholeNumber(challenges.dynamic.length - 1)];
        const noHolidays = challenge.includes('Artist');
        const isAlbum = challenge.includes('Album');
        if (challengeList.includes(challenge)) {
            const altChallenge = challenges.dynamic[altRandomWholeNumber(challenges.dynamic.length - 1)];
            const mergedAltChallenge = altChallenge.replace('{{modifier}}', randomModifier(includeSubgenres, noHolidays, isAlbum));
            setChallengeList([...challengeList, mergedAltChallenge]);
            return mergedAltChallenge;
        }
        const mergedChallenge = challenge.replace('{{modifier}}', randomModifier(includeSubgenres, noHolidays, isAlbum));
        setChallengeList([...challengeList, mergedChallenge]);
        return mergedChallenge;
    }
};

export const randomSingleChallenge = (includeSubgenres = false) => {
    const whichType = Math.random();
    if (whichType > .4) {
        return challenges.standard[randomWholeNumber(challenges.standard.length - 1)];
    }
    else {
        const challenge = challenges.dynamic[randomWholeNumber(challenges.dynamic.length - 1)];
        const noHolidays = challenge.includes('Artist');
        const isAlbum = challenge.includes('Album');
        return challenge.replace('{{modifier}}', randomModifier(includeSubgenres, noHolidays, isAlbum));
    }
};

export const getModifiers = () => {
    let obj = helpers;
    delete obj.subgenres;
    return obj;
};

export const getSubgenreKeys = () => {
    console.log(helpers.subgenres)
    return helpers.subgenres ? Object.keys(helpers.subgenres) : [];
};

export const getSubgenres = (subgenre) => {
    return helpers.subgenres[subgenre];
};
