import React, { Dispatch } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { randomSingleChallenge } from "../../lib/randomizer";
import '../../App.css';
import logo from '../../images/logo.svg'

interface aboutProps {
    show: boolean;
    close: Dispatch<React.SetStateAction<boolean>>;
};

function About({ show, close }: aboutProps) {
  return (
    <div className={`${show ? 'show' : 'hide'} About`}>
        <button type="button" className="close" onClick={() => close(false)}><FontAwesomeIcon icon={faClose} /></button>
      <header className="App-header">
          <img alt="Vinyl Challenge" className="white" src={logo} />
      </header>
      <main>
          <button type="button" className="rando" onClick={() => alert(randomSingleChallenge())}>
              Generate a Random Challenge!
          </button>
          <p>
              <b>Current Version: 0.1</b>
          </p>
        <p>
            Vinyl Challenge is an app that allows you to instantly create month-long vinyl
            challenges or quick one-off prompts from a randomly generated list with almost
            2,200 possibilities.
        </p>
          <h2>How Do I Use It?</h2>
          <p>
              Simple, the app starts by auto-filling a challenge list for the next month. To change the month, slide up the tray
              using the button at the bottom of the screen and use the Month Select Field to change the month. From there, you can
              also customize the title of the list, the fonts, and the background. You can also get a new, randomly-generated
              set of challenges. There is also an Add Subgenres checkbox. The App has a list of almost 1,000 subgenres you can add in
              to the randomly-generated challenges if you want to get challenges like "Favorite Stoner Metal Album" or "Favorite
              Schranz Artist".
          </p>
          <p>
              If you want to edit specific challenges (or if you get a double - I'm working on that), You can click or tap on
              any challenge to bring up the challenge editor. You can type in whatever challenge you'd like, generate a new
              random challenge, or use the edit button to launch the challenge builder which lets you navigate through the
              challenge list to build your own challenge.
          </p>
          <p>
              Once the list looks good, hit the download button at the top-right of the window and the app will download an
              image for you to share on social media.
          </p>
          <p>
              Also, you can click "Add to Homescreen" in the share menu of your phone's browser to install this app to your phone
              so you can use it offline anytime.
          </p>
          <h2>Why Did You Do This?</h2>
          <p>
              I love vinyl challenges. It's really fun to participate in them and it's a great opportunity to see other people's
              collections and what their musical tastes are. This app is the product of a Thanksgiving break from work in 2023
              and I'll try to add some more features in the future, but it's just a side project for fun.
          </p>
          <h2>What's Next?</h2>
          <p>
              I want to fix the duplication issue and add the ability to shake your phone to get a random challenge. I also want to
              add the ability to store your preferences, settings, and even your generated images in your phone's storage. Maybe
              some more font options and more challenges.
          </p>
          <h2>But How Will We Ever Repay You?</h2>
          <p>
              No need! This app is free and I have no interest in monetizing it. If you want to tag your post with <i>#vinylchallengeapp</i>,
              it would make it easy for me to find and participate in. You can contact me at <i>@adamwhitley</i> on Twitter if you have any
              questions or ideas. Lastly, if you want to buy me a coffee/beer (depending on the time of day) to show some love, my
              Venmo/Cashapp is @coconutradio/$coconutradio.
          </p>
          <i className="disclaimer">
            <a href="https://www.freepik.com/free-vector/set-gradient-cover-graphic-designs_2904355.htm#query=background%20pack&position=3&from_view=search&track=ais&uuid=82cbcdb8-8150-4c06-9274-4a43e6d35875">
                Background Images by rawpixel.com
            </a> on Freepik
          </i>
      </main>
    </div>
  );
}

export default About;
